import { Controller } from "stimulus";

export default class extends Controller {
    static targets = [

    ];

    initialize() {

    }
    connect() {
        this.priceNote = this.element.querySelector('[data-price-note]');
        this.priceCalculator = this.element.querySelector('[data-price-calculator]')
        this.ctaTarget = this.element.querySelector('[data-cta]')
        this.reserved = this.element.querySelector('[data-reserved]');
        console.log(this.data.get('depositId'));
        this.submitCallback = (e, formId, ...args) => {
            let depositId = parseInt(this.data.get('depositId'), 10);
            if(formId === depositId) {
                this.priceNote.style.display = "none";
                this.priceCalculator.style.display = "none";
                this.ctaTarget.style.display = "none";
                this.reserved.style.display = "block";
            }

        }
        jQuery(window.document).on("gform_confirmation_loaded", this.submitCallback);

        // console.log(this.priceCalculatorTarget);
        // console.log(this.ctaTarget);

    }
    disconnect() {
        jQuery(window.document).off("gform_confirmation_loaded", this.submitCallback);
    }
}