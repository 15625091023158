import React from "react";

export function isInitialValid(props) {
    if (!props.validationSchema) return true;
    return props.validationSchema.isValidSync(props.initialValues);
}

export function isFieldValid(name, props) {
    const { errors, touched, values } = props;
    // console.log(name);
    // if (name === 'firstName') {
    //     console.log('isFieldValidClass', touched[name], errors[name], values[name])
    // }

    return touched[name] && typeof errors[name] === "undefined";
}

export function isFieldValidClass(name, props) {
    const { touched } = props;

    // if not touched yet, don't set the class
    if (typeof touched[name] === "undefined") return "";

    return isFieldValid(name, props) ? "is-valid" : "is-invalid";
}

export function processMakes(makes) {
    if (!makes) return [];

    const options = [{ value: "", label: "Select brand" }];

    makes.forEach((make) => {
        options.push({
            value: make.MAKE_NAME,
            label: make.MAKE_NAME,
            id: make.MAKE_SEQ,
        });
    });

    return options;
}
export function groupBrands(brands, otherBrands, brandLabel = "Our Brands") {
    if (!brands) return [];
    if (!otherBrands) return [];

    const options = [];

    options.push({
        label: "Select brand",
        options: [...Object.values(brands)].map((brand) => {
            return {
                value: brand.post_name,
                label: brand.post_title,
                id: brand.ID,
            };
        }),
    });

    options.push({
        label: "Additional Brands",
        options: [...Object.values(otherBrands)].map((brand) => {
            return {
                value: brand.post_name,
                label: brand.post_title,
                id: brand.ID,
            };
        }),
    });

    // alphabetical sort
    // OTHER ends up in the middle not on the end
    // should handle order somewhere
    options.forEach((group) => {
        if (group.options) {
            group.options.sort((a, b) => {
                let makeA = a.value.toUpperCase();
                let makeb = b.value.toUpperCase();

                if (makeA < makeb) {
                    return -1;
                }
                if (makeA > makeb) {
                    return 1;
                }
                return 0;
            });
        }
    });

    options.unshift({ value: "", label: brandLabel });

    return options;
}

export function processMakesById(makes) {
    if (!makes) return [];

    const options = [];

    makes.forEach((make) => {
        options.push({
            value: make.post_name,
            label: make.post_title,
            id: make.ID,
        });
    });

    // alphabetical sort
    // OTHER ends up in the middle not on the end
    // should handle order somewhere
    options.sort((a, b) => {
        let makeA = a.value.toUpperCase();
        let makeb = b.value.toUpperCase();

        if (makeA < makeb) {
            return -1;
        }
        if (makeA > makeb) {
            return 1;
        }
        return 0;
    });

    options.unshift({ value: "", label: "Select brand" });

    return options;
}

export function processOtherBrands(otherBrands) {
    if (!otherBrands) return [];

    const options = [{ value: "", label: "Select other brand" }];

    otherBrands.forEach((brand) => {
        options.push({
            value: brand.post_name,
            label: brand.post_title,
            id: brand.ID,
        });
    });

    return options;
}

export function processModels(models) {
    if (!models) return [];
    const options = [{ value: "", label: "Select model" }];

    models.forEach((model) => {
        options.push({
            value: model.MODEL_NAME,
            label: model.MODEL_NAME,
            id: model.MAKE_SEQ,
        });
    });

    return options;
}

export function processRegions(regions) {
    if (!regions) return [];
    const options = [{ value: "", label: "Select region" }];

    regions.forEach((region) => {
        options.push({
            value: region.name,
            label: region.name,
            id: region["id"] || region["term_id"],
        });
    });

    return options;
}

export function groupDealerships(regions, label = "All Dealerships") {
    if (!regions) return [];

    const grouped = [{ value: "", label }];

    regions.forEach((region) => {
        grouped.push({
            label: region.name,
            options: [...Object.values(region.dealerships)].map((dealer) => {
                return {
                    value: dealer.post_title,
                    label: dealer.post_title,
                    dealer,
                };
            }),
        });
    });

    return grouped;
}

export function excludeDealerships(excludedDealers, dealershipsGrouped) {
    if (!excludedDealers) return dealershipsGrouped;

    dealershipsGrouped.forEach((region, index) => {
        if (region['options']) {
            excludedDealers.forEach((excluded) => {
                dealershipsGrouped[index]['options'] = region['options'].filter((dealer) =>
                    dealer.label !== excluded['post_title']);
            });
        }
    })
    return dealershipsGrouped;
}

export const formatGroupLabel = (data) => (
    <div className="select__grouped-label">
        <span>{data.label}</span>
        {/* <span>{data.options.length}</span> */}
    </div>
);

export function filterModelsByMake(models, makeID) {
    if (makeID) {
        return [models[0]].concat(
            models.filter((model) => parseInt(model.id) === parseInt(makeID))
        );
    } else {
        return [models[0]];
    }
}
